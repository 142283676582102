import React from "react";
import Layout from "../Components/Layout";
import "../assets/sass/pages/nuostatos.scss";

function Privatumas() {
  return (
    <Layout>
    <div className="Rules">
      <div className="rulesMain">
        <h2>Demokratijos žinių konkurso privatumo politika</h2>
        <span>Šioje privatumo politikoje išdėstyta, kaip mes tvarkome Jūsų asmens duomenis, kai Jūs dalyvaujate mūsų organizuojamame „Demokratijos žinių konkurse“ (Konkursas) naudodamiesi LRT portalo svetaine dzk.lrt.lt (LRT portalas). Privatumo politika gali būti keičiama ir tobulinama pasikeitus galiojančių teisės aktų nuostatoms, esant programinės įrangos funkcionalumo pokyčiams ar kitomis nenumatytomis aplinkybėmis apie pakeitimus bus pranešama LRT portale.
        </span>
        <h2>Duomenų valdytojai: </h2>
        <span>
        VšĮ „Lietuvos nacionalinis radijas ir televizija“ (LRT), juridinio asmens kodas 124241078, registracijos adresas S. Konarskio g. 49, Vilnius <br />
        Lietuvos Respublikos Seimo kanceliarija (Seimo kanceliarija), įmonės kodas 188605295, registracijos adresas Gedimino per. 53, Vilnius <br/>
        Detalius mūsų ir mūsų duomenų apsaugos pareigūnų kontaktinius duomenis, kuriais galite kreiptis į mus, rasite šios privatumo politikos 9 skyriuje. <br/> <br/>
        Užsukę į LRT portalą, pateikę savo asmens duomenis bei papildomus sutikimus, kai to aiškiai reikia pagal šią Privatumo politiką, Jūs mums suteikiate teisę rinkti, valdyti, tvarkyti ir saugoti Jūsų duomenis tokia apimtimi ir tokiais tikslais, kaip numatyta šioje privatumo politikoje. Jei naudojatės LRT portalu, vadinasi, Jūs perskaitėte ir susipažinote su šia privatumo politika ir joje nurodytais Jūsų asmens duomenų tvarkymo tikslais, būdais bei tvarka. Jeigu Jūs nesusipažinote su privatumo politika, Jūs negalėsite dalyvauti Demokratijos žinių konkurse. <br/> <br/> 
        Ši privatumo politika netaikoma LRT portale pateikiamoms nuorodoms į trečiųjų šalių tinklalapius, todėl rekomenduojame atskirai pasidomėti šių tinklalapių privatumo politika ar taisyklėmis.

        </span>
        <h2>Ar mes tvarkome vaikų asmens duomenis?</h2>
        <span>
        Konkursas skirtas asmenims ne jaunesniems nei 14 metų, kurie gali sutikti su jų asmens duomenų tvarkymu, kai tai susiję su paslaugų siūlymu internetu vaikui. <br /> <br/>
        <b>Jeigu Jūs esate jaunesnis nei 14 metų, prašome nepildyti Konkurso registracijos anketos ir nesiųsti mums savo asmens duomenų. </b> <br/> <br/>
Jeigu Jūs esate jaunesnio nei 14 metų vaiko tėvas/ motina ar įstatyminis globėjas ir Jums žinoma, kad Jūsų vaikas bet kokiu būdu pateikė mums savo asmens duomenis be Jūsų rašytinio sutikimo, prašome su mumis susisiekti, kad mes galėtume ištrinti Jūsų vaiko asmens duomenis.

        </span>
        <h2>1.	Kokius duomenis apie jus renkame? </h2>
        <span>
        Norėdami dalyvauti Konkurse turite mums pateikti tokius asmens ir kontaktinius duomenis: <br/><br/>
        &emsp;&#x25CF;&ensp;vardą;<br/>
        &emsp;&#x25CF;&ensp;pavardę;<br/>
        &emsp;&#x25CF;&ensp;elektroninio pašto adresą;<br/>
        &emsp;&#x25CF;&ensp;telefono numerį;<br/>
        &emsp;&#x25CF;&ensp;amžių;<br/>
        &emsp;&#x25CF;&ensp;gyvenamąjį miestą;<br/>
        &emsp;&#x25CF;&ensp;mokyklą bei klasę (jei esate moksleivis)<br/>
        &emsp;&#x25CF;&ensp;kokiu būdu sužinojote apie konkursą (pateikti neprivaloma).<br/><br/>
        Jums nepateikus nurodytų asmens duomenų, Jūs negalėsite dalyvauti Konkurse.<br/><br/>
        Jums lankantis LRT portale LRT gauna Jūsų prisijungimo techninę informaciją – slapukus. Visą informaciją apie tai, kas yra slapukai ir kaip jie yra tvarkomi rasite šios privatumo politikos 8 skyriuje.

        </span>
        <h2>2.	Iš kur gauname Jūsų duomenis?</h2>
        <span>
        LRT ir Seimo kanceliarija tvarko asmens duomenis, kuriuos Jūs patys pateikiate registruodamiesi LRT portale ir kurdami savo paskyrą. <br/><br/>
        Jei naudodamiesi LRT portalu pasirenkate prisijungimą prie savo paskyros per socialinius portalus, tokius kaip „Facebook“, jūs suteikiate „Facebook“ leidimą, pasidalinti Jūsų paskyros informacija su mumis – taigi, gautų duomenų šaltinis yra atitinkamai „Facebook“.

        </span>
        <h2>3.	Kokiais tikslais naudojame Jūsų duomenis?</h2>
        <span>
        Pagrindinis Jūsų asmens duomenų tvarkymo tikslas yra administruoti ir vykdyti konkursą.<br/>
        Mes taip pat tvarkome jūsų asmens duomenis LRT portale, kad:<br/><br/>
        &emsp;&#x25CF;&ensp;Jums būtų patogu naudotis LRT portalu ir jo veikimas atitiktų Jūsų poreikius;<br/>
        &emsp;&#x25CF;&ensp;būtų užtikrintas sklandus LRT portalo administravimas;<br/>
        &emsp;&#x25CF;&ensp;laiku ir tinkamai būtų pateikti atsakymai į Jūsų užklausas, prašymus;<br/>
        &emsp;&#x25CF;&ensp;būtų užtikrintas sklandus konkurso vykdymas; <br/>
        &emsp;&#x25CF;&ensp;būtų nuolat tobulinamas LRT portalo turinys, jame teikiant konkursui skirtas paslaugas.<br/><br/>
        Informuojame, jog Jūsų duomenis tvarkysime ir tiesioginės rinkodaros tikslais (klausdami Jūsų nuomonės apie įvykusį konkursą ar kviesdami dalyvauti konkurse ateityje). Jūsų elektroninio pašto kontaktinius duomenis tiesioginės rinkodaros tikslais tvarkysime be atskiro sutikimo, vadovaudamiesi Lietuvos Respublikos elektroninių ryšių įstatymo 81 straipsnio 2 dalimi. Siekdami teisėto intereso tinkamai įgyvendinti minėtas įstatymo nuostatas ir Seimo kanceliarijos intereso organizuoti analogiškus konkursus ateityje, tvarkysime ir analizuosime informaciją apie Jūsų dalyvavimą konkurse. Jeigu prieštaraujate tokiam asmens duomenų tvarkymui ir nenorite gauti pranešimų apie mūsų organizuojamus konkursus ateityje, galite bet kuriuo metu pateikti prieštaravimą bet kuriais privatumo politikos 9 skyriuje nurodytais kontaktais.
        </span>
        <h2>4.	Kokiu teisiniu pagrindu tvarkome Jūsų duomenis?</h2>
        <span>
        Nurodyti Jūsų asmens duomenys yra tvarkomi Jūsų sutikimo pagrindu. LRT ir Seimo kanceliarija tvarko asmens duomenis, kuriuos Jūs patys pateikiate registruodamiesi LRT portale ir kurdami savo paskyrą. Prieš užpildant ir išsiunčiant konkurso registracijos formą Jums yra privaloma susipažinti su šia privatumo politika bei konkurso nuostatais, todėl laisva valia bei savo aktyviais veiksmais pateikdami mums užpildytą registracijos formą Jūs išreiškiate sutikimą, kad Jūsų pateikti asmens duomenys būtų tvarkomi konkurso administravimo ir vykdymo tikslais.<br/> <br/>
        Kaip jau anksčiau minėjome, elektroniniu paštu su jumis susisieksime, norėdami sužinoti Jūsų nuomonę apie konkursą ar pasiūlyti Jums dalyvauti kitame konkurse teisėto intereso pagrindu.

        </span>
        <h2>5.	Kiek laiko saugosime Jūsų duomenis?</h2>
        <span>
        Jūsų duomenys saugomi 3 mėn. po įvykusio konkurso pabaigos.<br/> <br/>
        Jeigu Jūs neišreiškėte prieštaravimo gauti kvietimus į Seimo kanceliarijos organizuojamus panašius konkursus ateityje, Jūsų duomenys saugomi, kol bus organizuojami panašūs konkursai arba kol Jūs neišreikšite prieštaravimo tvarkyti duomenis šiuo tikslu.<br/><br/> 
        Ilgiau Jūsų asmens duomenys gali būti saugomi tik šiais atvejais:<br/><br/>
        &emsp;&#x25CF;&ensp;kai esama pagrįstų įtarimų dėl neteisėtos veikos, dėl kurios yra atliekamas tyrimas;<br/>
        &emsp;&#x25CF;&ensp;kai Jūsų asmens duomenys būtini tam, kad būtų tinkamai išspręstas ginčas ar skundas;<br/>
        &emsp;&#x25CF;&ensp;esant teisės aktuose numatytam pagrindui, kuris mus įpareigoja duomenis saugoti ilgiau.<br/><br/>
        Konkurso rezultatai (laimėjusio asmens (komandos dalyvių) vardas, pavardė, amžius, miestas, ugdymo įstaiga ir klasė (jei konkurso dalyvis yra moksleivis) ir kita su konkursu susijusi informacija (dalyvių skaičius, nuorodos į konkurso metu pateiktus darbus ir pan.) archyvavimo ir istoriniais tikslais saugomi nuolat.

        </span>
        <h2>6.	Kam teiksime Jūsų duomenis?</h2>
        <span>
        Tam tikri Jūsų asmens duomenys gali būti perduoti mūsų įgaliotiems duomenų tvarkytojams, kurie pagal su mumis sudarytas sutartis teikia mums tam tikras paslaugas. Tvarkydami Jūsų duomenis mes taip pat galime pasitelkti IT paslaugas teikiančią bendrovę, bei bendrovę teikiančią serverių nuomos ar debesijos paslaugas. Jei turite klausimų dėl mūsų duomenų tvarkytojų, kreipkitės privatumo politikos 9 skyriuje nurodytais kontaktais.<br/><br/>
        Esant būtinybei, kiek tai bus reikalinga mūsų teisėtam interesui užtikrinti (pavyzdžiui, kilus ginčui ir siekiant apginti savo teises bei interesus), Jūsų asmens duomenis galime pateikti teisines, audito ir kitas profesines paslaugas teikiantiems paslaugų teikėjams.<br/><br/>
        Kitais atvejais duomenų neperduosime jokioms trečiosioms šalims, nebent tai padaryti būtume įpareigoti teisės aktais arba jei Jūs duotumėte mums aiškų atskirą sutikimą, kad jūsų duomenys būtų perduodami.<br/><br/>
        Mes neplanuojame jūsų asmens duomenų siųsti į šalis už Europos Ekonominės Erdvės (EEE) ribų ar tarptautinėms organizacijoms. Vis dėlto, jei atsitiktų, kad mūsų pasirinkti duomenų tvarkytojai vykdytų Jūsų duomenų tvarkymą už EEE, pavyzdžiui, jeigu jų naudojami serveriai būtų už šios erdvės ribų, mes įsipareigosime imtis visų būtinų ir tinkamų veiksmų, kad užtikrintume jūsų duomenų saugumą laikantis asmens duomenų apsaugos teisės aktų reikalavimų.

        </span>
        <h2>7.	Kokias turite teises ir kaip jas galite įgyvendinti?</h2>
        <span>
        Vadovaujantis asmens duomenų apsaugą reglamentuojančiais teisės aktais, Jūs turite šias teises:<br/><br/>
        &emsp;&#x25CF;&ensp;sužinoti (būti informuotu), kokius Jūsų asmens duomenis tvarko LRT ir Seimo kanceliarija;<br/>
        &emsp;&#x25CF;&ensp;susipažinti su savo asmens duomenimis, kuriuos mes tvarkome;<br/>
        &emsp;&#x25CF;&ensp;reikalauti ištaisyti neteisingus, netikslius savo asmens duomenis;<br/>
        &emsp;&#x25CF;&ensp;reikalauti ištrinti visus apie Jus sukauptus duomenis, kai jie daugiau nereikalingi tikslams, kuriems buvo surinkti;<br/>
        &emsp;&#x25CF;&ensp;reikalauti sunaikinti asmens duomenis, jei jie tvarkomi neteisėtai;<br/>
        &emsp;&#x25CF;&ensp;reikalauti apriboti duomenų tvarkymą, jei tai atitinka įstatymu apibrėžtus kriterijus;<br/>
        &emsp;&#x25CF;&ensp;prieštarauti Jūsų asmens duomenų tvarkymui, kai mes Jūsų duomenis tvarkome dėl teisėto intereso;<br/>
        &emsp;&#x25CF;&ensp;atšaukti duotą sutikimą naudoti Jūsų duomenis konkurso administravimo ir vykdymo tikslu bei slapukams.<br/><br/>
        Visas šias aukščiau išvardintas savo teises Jūs galite įgyvendinti pateikdami mums rašytinį prašymą/pranešimą šios Privatumo politikos 9 skyriuje nurodytais kontaktais. Gavę tokį Jūsų prašymą ar nurodymą, mes ne vėliau kaip per 30 (trisdešimt) kalendorinių dienų pateiksime atsakymą apie atliktus prašyme nurodytus veiksmus ar motyvuotai atsisakysime juos atlikti.<br/><br/>
        Jums pateikus prašymą, susijusį su aukščiau nurodytų teisių įgyvendinimu, mums gali būti reikalinga nustatyti Jūsų asmens tapatybę dėl ko mes galime paprašyti Jūsų pateikti papildomų duomenų ar dokumentų. Visi šie papildomi duomenys bus naudojami išimtinai tik Jūsų tapatybės nustatymo tikslais ir tai padarius bus nedelsiant saugiai mūsų ištrinti.<br/><br/>
        Informuojame, kad Jūs turite teisę skųsti LRT ir Seimo kanceliarijos veiksmus (neveikimą) Valstybinei duomenų apsaugos inspekcijai ir teismui teisės aktų nustatyta tvarka. Prieš pateikiant skundą atitinkamai institucijai, rekomenduojame susisiekti su LRT ar Seimo kanceliarijos duomenų apsaugos pareigūnu privatumo politikos 9 skyriuje nurodytais kontaktais.

        </span>
        <h2>8.	Slapukai</h2>
        <span>
        LRT portale mes naudojame slapukus (angl. cookies) tik gavę išankstinį naudotojo sutikimą, išskyrus būtinuosius slapukus, siekdami kiek įmanoma labiau personalizuoti Jūsų lankomo LRT portalo veikimą. Slapukais siekiame:<br/><br/>
        &emsp;&#x25CF;&ensp;išvengti pakartotinės registracijos ir anketų pildymo nuolatiniams portalo lankytojams;<br/>
        &emsp;&#x25CF;&ensp;atpažinti sugrįžtančius portalo lankytojus;<br/>
        &emsp;&#x25CF;&ensp;analizuoti Jūsų įpročius, kad portalo veikimas būtų patogus, efektyvus ir atitiktų Jūsų poreikius bei lūkesčius.<br/>
        <h4>8.1. Kas yra slapukai?</h4>
        Slapukai yra nedideli tekstiniai failai, kuriuos Jūsų interneto naršyklė įdiegia Jūsų kompiuteryje, planšetėje arba išmaniajame įrenginyje. Jie leidžia portalui išsaugoti tokius duomenis, kaip įrenginio IP adresas, naršyklės tipas, demografinius duomenis (amžiaus grupė, lytis), duomenis apie tai, kaip Jūs naudojatės portalu (kokius puslapius vartote, kokiu turiniu domitės).<br/>
        Slapukai LRT portalui suteikia „atmintį“ – kiekvieno apsilankymo metu Jūs esate atpažįstamas kaip nuolatinis portalo lankytojas.<br/>

        <h4>8.2. Kaip slapukai naudojami LRT portale?</h4>
        Apsilankius LRT portale, gali būti sukurti tokio tipo slapukai: Būtinieji slapukai – slapukai, naudojami vykdant tokias užklausas kaip prisijungimas prie vartotojo paskyros.<br/><br/>

        „Google Analytics“ žymėjimo ir elgsenos slapukai – slapukai, kurie mums pasako, ar Jūs esate lankęsis LRT portale, ar turite išsaugoję ankstesnių apsilankymų slapukus. Šie slapukai leidžia nustatyti unikalių LRT portalo lankytojų skaičių ir jų apsilankymo dažnumą. Šie slapukai mums padeda rinkti ir sisteminti statistinę informaciją;<br/>
        _autuvc; _atuvs; _gat; – galioja momentiškai;<br/>
        _utma; _ga; _gfp_64b; _atuvc – galioja dvejus metus;<br/>
        _utmz – galioja 6 mėnesius; _gid – galioja vieną dieną.<br/><br/>

        Trečiųjų šalių reklaminiai slapukai – daug skelbimų, kuriuos matote LRT portale, yra pateikti kitų ūkio subjektų. Kai kurie šie subjektai patys naudoja anoniminius slapukus turėdami tikslą žinoti, kiek žmonių matė konkretų skelbimą arba kiek žmonių matė jį daugiau negu vieną kartą. Tokius slapukus kuriančios bendrovės taiko savo parengtas politikas ir mes neturime jokios įtakos tokių slapukų kūrimui ar saugojimui. Mes Jums rekomenduojame atskirai pasidomėti tų bendrovių privatumo ar slapukų politika, kuri paprastai būna paskelbta jų interneto tinklalapyje.<br/><br/>
        Kiti trečiųjų šalių slapukai – kai kuriuose LRT portalo puslapiuose kiti subjektai taip pat gali naudoti savo pačių anoniminius slapukus, skirtus tam, kad jų sukurtos programos ar programėlės būtų pritaikytos Jūsų poreikiams. Dėl slapukų veikimo specifikos LRT portalas neturi prieigos prie šių slapukų, lygiai kaip ir kiti subjektai neturi prieigos prie mūsų nustatomų slapukų.<br/>

        <h4>8.3. Kaip išjungti slapukus ir nebesutikti su jų rinkimu?</h4>
        Daugelis interneto naršyklių yra nustatytos taip, kad automatiškai priimtų slapukus. Bet kuriuo Jums patogiu metu slapukus galite išjungti ir tokiu būdu nebesutikti, kad LRT portalas juos rinktų, tačiau taip padarius bus sumažintas portalo funkcionalumas, todėl jis gali neveikti taip, kaip dera, ir kaip veikė iki tokio atsisakymo.<br/><br/>

        Visos šiuolaikinės interneto naršyklės leidžia keisti slapukų nustatymus. Paprastai šiuos nustatymus galite rasti savo naršyklės parinkčių arba pasirinkimų (angl. Settings, Internet Options ir pan.) meniu:<br/><br/>
        &emsp;&#x25CF;&ensp;<a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-10" target="_blank" rel="noreferrer">Slapukų nustatymai</a> Internet Explorer<br/>
        &emsp;&#x25CF;&ensp;<a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies" target="_blank" rel="noreferrer">Slapukų nustatymai</a> Firefox<br/>
        &emsp;&#x25CF;&ensp;<a href="https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666" target="_blank" rel="noreferrer">Slapukų nustatymai</a> Chrome<br/>
        &emsp;&#x25CF;&ensp;<a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac" target="_blank" rel="noreferrer">Slapukų nustatymai</a> Safari web ir iOS<br/><br/>
        Kad suprastumėte šiuos nustatymus, Jums gali padėti pateiktos nuorodos, arba galite pasinaudoti savo naršyklės pagalbos (angl. Help) pasirinkimu.

        </span>
        <h2>9.	Mūsų kontaktinė informacija</h2>
        <span>
        Į LRT galite kreiptis šiais būdais:<br/><br/>
        &emsp;&#x25CF;&ensp;registruotu paštu siunčiant adresu S. Konarskio g. 49, Vilnius;<br/>
        &emsp;&#x25CF;&ensp;elektroniniu paštu adresu <a href="mailto:lrt@lrt.lt">lrt@lrt.lt</a>.<br/><br/>
        Su LRT duomenų apsaugos pareigūnu galite susiekti:<br/><br/>
        &emsp;&#x25CF;&ensp;elektroniniu paštu adresu <a href="mailto:dap@lrt.lt">dap@lrt.lt</a>.<br/>
        &emsp;&#x25CF;&ensp;telefonu: +3705 236 3292<br/><br/>
        Į Seimo kanceliariją galite kreiptis šiais būdais:<br/><br/>
        &emsp;&#x25CF;&ensp;el. pašto adresu <a href="mailto:konkursas@lrs.lt">konkursas@lrs.lt</a>; <a href="mailto:priim@lrs.lt">priim@lrs.lt;</a><br/>
        &emsp;&#x25CF;&ensp;korespondencijos adresu Gedimino pr. 53, Vilnius.<br/><br/>
        Su Seimo kanceliarijos duomenų apsaugos pareigūnu galite susisiekti:<br/><br/>
        &emsp;&#x25CF;&ensp;el. pašto adresu <a href="mailto:dap@lrs.lt">dap@lrs.lt</a>;<br/>
        &emsp;&#x25CF;&ensp;korespondencijos adresu Gedimino pr. 53, Vilnius – laišką adresuokite Seimo kanceliarijos duomenų apsaugos pareigūnui.<br/>

        </span>
      </div>
    </div>
    </Layout>
  );
}

export default Privatumas;